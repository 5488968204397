import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

const headers = new HttpHeaders({
  'Content-Type':  'application/json',
  'Access-Control-Allow-Origin': '*'
});

@Injectable({
  providedIn: 'root'
})

export class CarsService {

  constructor(
    private http: HttpClient
  ) { }

  getOffers(filters) {

    let params = new HttpParams();

    if (filters.limit) {
      params = params.append('limit' , filters.limit);
    }

    if (filters.skip) {
      params = params.append('skip' , filters.skip);
    }

    return this.http.get( environment.apiUrl + '/cars/latestOffers', {params, headers})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  carsSearchOffers(filters) {
    let params = new HttpParams();

    if (filters.type) {
      params = params.append('type' , filters.type);
    }

    if (filters.status) {
      params = params.append('status' , filters.status);
    }

    if (filters.carBrandID) {
      params = params.append('carBrandID' , filters.carBrandID);
    }

    if (filters.carModelID) {
      params = params.append('carModelID' , filters.carModelID);
    }

    if (filters.branchID) {
      params = params.append('branchID' , filters.branchID);
    }

    if (filters.kmFrom) {
      params = params.append('kmFrom' , filters.kmFrom);
    }

    if (filters.kmTo) {
      params = params.append('kmTo' , filters.kmTo);
    }

    if (filters.budgetTo) {
      params = params.append('budgetTo' , filters.budgetTo);
    }

    if (filters.budgetFrom) {
      params = params.append('budgetFrom' , filters.budgetFrom);
    }

    if (filters.limit) {
      params = params.append('limit' , filters.limit);
    }

    if (filters.skip) {
      params = params.append('skip' , filters.skip);
    }

    if (filters.order) {
      params = params.append('order' , filters.order);
    }

    return this.http.get( environment.apiUrl + '/cars/carsSearchOffers', {params, headers})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  carsSearchOffersCounter(filters) {
    let params = new HttpParams();

    if (filters.type) {
      params = params.append('type' , filters.type);
    }

    if (filters.status) {
      params = params.append('status' , filters.status);
    }

    if (filters.carBrandID) {
      params = params.append('carBrandID' , filters.carBrandID);
    }

    if (filters.branchID) {
      params = params.append('branchID' , filters.branchID);
    }

    if (filters.carModelID) {
      params = params.append('carModelID' , filters.carModelID);
    }

    if (filters.kmFrom) {
      params = params.append('kmFrom' , filters.kmFrom);
    }

    if (filters.kmTo) {
      params = params.append('kmTo' , filters.kmTo);
    }

    if (filters.budgetTo) {
      params = params.append('budgetTo' , filters.budgetTo);
    }

    if (filters.budgetFrom) {
      params = params.append('budgetFrom' , filters.budgetFrom);
    }

    return this.http.get( environment.apiUrl + '/cars/carsSearchOffersCounter', {params, headers})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  carsOffersMajorDiscount(filters) {

    let params = new HttpParams();

    if (filters.type) {
      params = params.append('type' , filters.type);
    }

    return this.http.get( environment.apiUrl + '/cars/carsOffersMajorDiscount', {params, headers})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getRelatedCars(filters: any) {
    let params = new HttpParams().set('id', filters.id);

    if (filters.carsNumber) {
      params = params.append('carsNumber' , filters.carsNumber);
    }

    return this.http.get( environment.apiUrl + '/cars/getRelatedCars', {params, headers})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getCars(filters) {

    let params = new HttpParams();

    if (filters.budgetFrom) {
      params = params.append('budgetFrom' , filters.budgetFrom);
    }

    if (filters.budgetTo) {
      params = params.append('budgetTo' , filters.budgetTo);
    }

    if (filters.order) {
      params = params.append('order' , filters.order);
    }

    if (filters.dateManufacture) {
      params = params.append('dateManufacture' , filters.dateManufacture);
    }

    if (filters.yearManufactureTo) {
      params = params.append('yearManufactureTo' , filters.yearManufactureTo);
    }

    if (filters.yearManufactureFrom) {
      params = params.append('yearManufactureFrom' , filters.yearManufactureFrom);
    }

    if (filters.isOffer || filters.isOffer == 0) {
      params = params.append('isOffer' , filters.isOffer);
    }

    if (filters.keyword) {
      params = params.append('keyword' , filters.keyword);
    }

    if (filters.kmFrom) {
      params = params.append('kmFrom' , filters.kmFrom);
    }

    if (filters.kmTo) {
      params = params.append('kmTo' , filters.kmTo);
    }

    if (filters.power) {
      params = params.append('power' , filters.power);
    }

    if (filters.status) {
      params = params.append('status' , filters.status);
    }

    if (filters.carBrandID) {
      params = params.append('carBrandID' , filters.carBrandID);
    }

    if (filters.carModelID) {
      params = params.append('carModelID' , filters.carModelID);
    }

    if (filters.carBodyID) {
      params = params.append('carBodyID' , filters.carBodyID);
    }

    if (filters.mercedesBenzCertified) {
      params = params.append('mercedesBenzCertified' , filters.mercedesBenzCertified);
    }


    if (filters.branchID) {
      params = params.append('branchID' , filters.branchID);
    }

    if (filters.limit) {
      params = params.append('limit' , filters.limit);
    }

    if (filters.skip) {
      params = params.append('skip' , filters.skip);
    }

    if (filters.stockOnline == 1) {
      params = params.append('stockOnline' , 1);
    } else if (filters.stockOnline == 0) {
      params = params.append('stockOnline' , 0);
    }

    if (filters.traction) {
      params = params.append('traction' , filters.traction);
    }

    if (filters.transmission) {
      params = params.append('transmission' , filters.transmission);
    }

    if (filters.fuel) {
      params = params.append('fuel' , filters.fuel);
    }

    if (filters.color) {
      params = params.append('color' , filters.color);
    }

    if (filters.cylinder) {
      params = params.append('cylinder' , filters.cylinder);
    }

    return this.http.get( environment.apiUrl + '/cars/carSearch', {params, headers})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getCarsCounter(filters) {
    let params = new HttpParams();

    if (filters.budgetFrom) {
      params = params.append('budgetFrom' , filters.budgetFrom);
    }

    if (filters.budgetTo) {
      params = params.append('budgetTo' , filters.budgetTo);
    }

    if (filters.dateManufacture) {
      params = params.append('dateManufacture' , filters.dateManufacture);
    }

    if (filters.yearManufactureTo) {
      params = params.append('yearManufactureTo' , filters.yearManufactureTo);
    }

    if (filters.yearManufactureFrom) {
      params = params.append('yearManufactureFrom' , filters.yearManufactureFrom);
    }

    if (filters.isOffer || filters.isOffer == 0) {
      params = params.append('isOffer' , filters.isOffer);
    }

    if (filters.keyword) {
      params = params.append('keyword' , filters.keyword);
    }

    if (filters.kmFrom) {
      params = params.append('kmFrom' , filters.kmFrom);
    }

    if (filters.kmTo) {
      params = params.append('kmTo' , filters.kmTo);
    }

    if (filters.power) {
      params = params.append('power' , filters.power);
    }

    if (filters.status) {
      params = params.append('status' , filters.status);
    }

    if (filters.carBrandID) {
      params = params.append('carBrandID' , filters.carBrandID);
    }

    if (filters.carModelID) {
      params = params.append('carModelID' , filters.carModelID);
    }

    if (filters.carBodyID) {
      params = params.append('carBodyID' , filters.carBodyID);
    }

    if (filters.mercedesBenzCertified) {
      params = params.append('mercedesBenzCertified' , filters.mercedesBenzCertified);
    }


    if (filters.branchID) {
      params = params.append('branchID' , filters.branchID);
    }

    if (filters.stockOnline == 1) {
      params = params.append('stockOnline' , 1);
    } else if (filters.stockOnline == 0) {
      params = params.append('stockOnline' , 0);
    }

    if (filters.traction) {
      params = params.append('traction' , filters.traction);
    }

    if (filters.transmission) {
      params = params.append('transmission' , filters.transmission);
    }

    if (filters.fuel) {
      params = params.append('fuel' , filters.fuel);
    }

    if (filters.color) {
      params = params.append('color' , filters.color);
    }

    if (filters.cylinder) {
      params = params.append('cylinder' , filters.cylinder);
    }

    return this.http.get( environment.apiUrl + '/cars/carSearchCounter', {params, headers})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getCar (slug: any) {
    let params = new HttpParams().set('slug', slug);
    return this.http.get( environment.apiUrl + '/cars/getCar', { params, headers })
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getBrands () {
    return this.http.get( environment.apiUrl + '/cars/misc/getBrands', { headers})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getModels () {
    return this.http.get( environment.apiUrl + '/cars/misc/getCarModels', { headers})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getParkedCars () {
    return this.http.get( environment.apiUrl + '/cars/getParkedCars', { headers})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getMyCards() {
    return this.http.get( environment.apiUrl + '/cars/user-cars', { headers})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  saveMyCar(data: any) {
    return this.http.post(environment.apiUrl + '/cars/user-cars', data, {headers}).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  updateMyCar(data: any) {
    const id = JSON.parse(JSON.stringify(data)).id;
    delete data.errorNo;
    delete data.id;

    return this.http.put(environment.apiUrl + '/cars/user-cars/' + id, data, {headers}).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  deleteCar(id: number) {
    return this.http.delete(environment.apiUrl + '/cars/user-cars/' + id, {headers}).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getCarBody () {
    return this.http.get( environment.apiUrl + '/cars/misc/getCarBody', { headers})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getAllMisc () {
    return this.http.get( environment.apiUrl + '/cars/misc/getAll', { headers})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  removeAllCarParked() {
    return this.http.post(environment.apiUrl + '/cars/removeAllCarParked', {}, {headers}).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  parkCar(data: any) {
    return this.http.post(environment.apiUrl + '/cars/parkCar', data, {headers}).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  removeFromGarage(data: any) {
    return this.http.post(environment.apiUrl + '/cars/removeCarParked', data, {headers}).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  updateParkedCars(data: any) {
    return this.http.post(environment.apiUrl + '/cars/updateParkedCars', data, {headers}).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  carReservation(data: any) {
    data.name = data.name.trim();
    return this.http.post(environment.apiUrl + '/car-reservation/save', data, {headers}).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

}


